import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../api";
import { Collection, CollectionPlayer } from "./models";
import { queryKeys } from "./queryKeys";
import { MutationConfig, MutationResult } from "../utils/types";

export function useSession(sessionId: string) {
  const { run } = useApi();
  async function getSession() {
    return await run<Collection>({
      method: "GET",
      path: `/collections/${sessionId}`,
    });
  }
  const querySession: UseQueryOptions<Collection> = {
    queryKey: queryKeys.session.detail(sessionId),
    queryFn: getSession,
  };
  return useQuery(querySession);
}

export function useSessions(
  params: {
    startDate?: string;
    endDate?: string;
  } = {},
) {
  const { run } = useApi();
  async function getSessions() {
    return await run<Collection[]>({
      method: "GET",
      path: "/collections",
      params,
    });
  }
  const querySessions: UseQueryOptions<Collection[]> = {
    queryKey: queryKeys.session.all,
    queryFn: getSessions,
  };
  return useQuery(querySessions);
}

export function useCreateSession(
  config?: MutationConfig<Collection, typeof createSession>,
): MutationResult<Collection, typeof createSession> {
  const { run } = useApi();
  async function createSession(payload: { title?: string; startDate: string }) {
    return await run<Collection>({
      method: "POST",
      path: "/collections",
      body: payload,
    });
  }
  return useMutation({
    ...config,
    mutationFn: createSession,
  });
}

export function useAddPlayerToSession(
  sessionId: string,
  config?: MutationConfig<CollectionPlayer, typeof addPlayerToSession>,
): MutationResult<CollectionPlayer, typeof addPlayerToSession> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function addPlayerToSession(playerId: string): Promise<CollectionPlayer> {
    return await run<CollectionPlayer>({
      path: `/collections/${sessionId}/players`,
      method: "POST",
      body: { playerId },
    });
  }
  return useMutation({
    ...config,
    mutationFn: addPlayerToSession,
    onSettled: async () =>
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.players(sessionId),
      }),
  });
}

export function useRemovePlayerFromSession(
  sessionId: string,
  config?: MutationConfig<CollectionPlayer, typeof removePlayerFromSession>,
): MutationResult<CollectionPlayer, typeof removePlayerFromSession> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function removePlayerFromSession(playerId: string): Promise<CollectionPlayer> {
    return await run<CollectionPlayer>({
      path: `/collections/${sessionId}/players/${playerId}`,
      method: "DELETE",
    });
  }
  return useMutation({
    ...config,
    mutationFn: removePlayerFromSession,
    onSettled: async () =>
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.players(sessionId),
      }),
  });
}
