import { ContentContainer } from "../../components/ContentContainer";
import { LoadingSpinner } from "../../components/Spinner";
import { SDTable } from "../../components/table/SDTable";
import { SDTableContainer, SDTableContainerHeader } from "../../components/table/SDTableContainer";
import { configureTableProps } from "../../components/table/sport-configs/basketball";
import { useGame } from "../../lib/data/game";
import { usePlayersByGame } from "../../lib/data/players";
import { useGameStats } from "../../lib/data/stats";

interface GameStatsTableProps {
  gameId: string;
}
export function GameStatsTable({ gameId }: GameStatsTableProps) {
  const { data: game } = useGame(gameId);
  const { status: statsStatus, data: gameStats = {} } = useGameStats(gameId, {
    enabled: !!game,
  });
  const { data: teams = { homeTeam: [], awayTeam: [] } } = usePlayersByGame(gameId);
  const homeTeamTableData = configureTableProps(teams.homeTeam, gameStats);
  const awayTeamTableData = configureTableProps(teams.awayTeam, gameStats);
  if (statsStatus === "pending") return <LoadingSpinner />;
  return (
    <ContentContainer className="pt-4">
      <SDTableContainer>
        <SDTableContainerHeader title={"Team 1"} detail={`${teams.homeTeam.length} players`} />
        <SDTable headers={homeTeamTableData[0]} data={homeTeamTableData[1]}></SDTable>
      </SDTableContainer>
      <SDTableContainer>
        <SDTableContainerHeader title={"Team 2"} detail={`${teams.awayTeam.length} players`} />
        <SDTable headers={awayTeamTableData[0]} data={awayTeamTableData[1]}></SDTable>
      </SDTableContainer>
    </ContentContainer>
  );
}
