import { Link, useParams } from "react-router-dom";
import { ContentContainer } from "../../components/ContentContainer";
import { PageContainer } from "../../components/PageContainer";
import { usePlayersByGame } from "../../lib/data/players";
import { GameStat, PlayerWithDetails } from "../../lib/data/models";
import { useState } from "react";
import { GameStatsBySport, useGameScore } from "../../lib/data/stats";
import { Button } from "../../components/Button";
import { GameScore } from "../games/GameScore";
import { fullName } from "../../lib/utils/format";
import { PlayerList } from "../stats/PlayerList";
import { StatButtonList } from "../stats/StatButtonList";
import { NotFoundPage } from "./NotFound";

export function GameStatKeeperPage() {
  const { id } = useParams();
  if (!id) return <NotFoundPage returnRoute="/dashboard" />;
  return (
    <PageContainer>
      <ContentContainer>
        <div className="flex flex-row justify-between">
          <Link to={`/game/${id}`}>
            <Button intent="yellow" label="back to game" />
          </Link>
          <Link to={`/game/${id}?tab=feed`}>
            <Button intent="yellow" label="show feed" />
          </Link>
        </div>
        <PageContent gameId={id} />
      </ContentContainer>
    </PageContainer>
  );
}

function PageContent({ gameId }: { gameId: string }) {
  const [selectedPlayerId, setSelectedPlayer] = useState<string>("");
  const { data: teams = { homeTeam: [], awayTeam: [] } } = usePlayersByGame(gameId);
  const { data: score = { homeTeam: 0, awayTeam: 0 } } = useGameScore(gameId);
  const onSelect = (playerId: string) => setSelectedPlayer(playerId);

  const makeTeamList = (team: PlayerWithDetails[]) => (
    <PlayerList players={team} selectedPlayerId={selectedPlayerId} onSelect={onSelect} />
  );
  const homeTeamPlayers = makeTeamList(teams.homeTeam);
  const awayTeamPlayers = makeTeamList(teams.awayTeam);
  const disableButtons = selectedPlayerId === "";

  const currentPlayer: PlayerWithDetails | undefined = teams.awayTeam
    .concat(teams.homeTeam)
    .find(p => p.id === selectedPlayerId);

  const stats: GameStat[] = GameStatsBySport.basketball;

  const statSection = (
    <div className="flex flex-row justify-between">
      {homeTeamPlayers}
      <StatButtonList
        selectedPlayerId={selectedPlayerId}
        gameId={gameId}
        stats={stats}
        disabled={disableButtons}
        player={currentPlayer}
      />
      {awayTeamPlayers}
    </div>
  );

  const selectedPlayerSection = (
    <div className="text-center">
      {currentPlayer ? (
        <div className="text-grey-dark">
          Selected: <strong>{fullName(currentPlayer.user)}</strong>
        </div>
      ) : (
        <div className="text-grey-dark">Please select a player</div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="">
        <GameScore score={score} size="md" />
      </div>
      {selectedPlayerSection}
      {statSection}
    </div>
  );
}
