import { Link } from "react-router-dom";
import SvgIcon from "./svgr/Icon";

export function Footer() {
  return (
    <div className="w-full relative bottom-0 py-10 items-center bg-brown">
      <div className="m-auto px-3 max-w-screen-sm">
        <div className="flex flex-row justify-center items-center">
          <SvgIcon width={50} className="max-w-[150px] fill-white" aria-label="logo icon" />
        </div>
        <div className="flex flex-col gap-2 mt-4">
          <FooterLink label="About" href="/" />
          <FooterLink label="Terms & Conditions" href="/" />
          <FooterLink label="Privacy Notice" href="/" />
          <FooterLink label="Send Us Feedback" href="/" />
          <div className="text-white font-medium text-md" style={{ color: "white" }}>
            © 2024 | Somedubs | All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
}

function FooterLink({ href, label }: { label: string; href: string }) {
  return (
    <Link className="text-white font-medium text-md" to={href} style={{ color: "white" }}>
      {label}
    </Link>
  );
}
