import { GameEvent, GameStat, PlayerWithDetails } from "../../lib/data/models";
import { statLabels } from "../../lib/data/stats";
import { fullName } from "../../lib/utils/format";

export function getMessage(stat: GameStat, player: PlayerWithDetails | undefined) {
  const forPlayerText = player ? `for ${fullName(player.user)}` : "";
  switch (stat) {
    case "basketball_score":
    case "basketball_miss":
    case "basketball_rebound":
    case "basketball_assist":
    case "basketball_steal":
    case "basketball_turnover":
    case "basketball_block":
      return `Logged ${statLabels[stat]} ${forPlayerText}`.trim();
  }
}

export function feedMessage(event: GameEvent) {
  switch (event.stat) {
    case "basketball_score":
      return `Scored ${event.occurrences} ${event.occurrences > 1 ? `points` : `point`}`;
    case "basketball_miss":
      return `Missed a shot attempt.`;
    case "basketball_rebound":
      return `Rebounded the ball.`;
    case "basketball_assist":
      return `Got an assist.`;
    case "basketball_steal":
      return `Steals the ball!`;
    case "basketball_turnover":
      return `Boo! Turnover!`;
    case "basketball_block":
      return `Got a block!`;
  }
}
