import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../api";
import { Game } from "./models";
import { queryKeys } from "./queryKeys";
import { MutationResult, MutationConfig } from "../utils/types";

export function useGame(gameId: string) {
  const { run } = useApi();
  const fn = async () => await run<Game>({ method: "GET", path: `/games/${gameId}` });
  const query = {
    queryKey: queryKeys.games.detail(gameId),
    queryFn: fn,
    staleTime: 60000,
  };
  return useQuery(query);
}

export function useGamesBySession(sessionId: string) {
  const { run } = useApi();

  async function getGamesBySession() {
    return await run<Game[]>({
      method: "GET",
      path: `/collections/${sessionId}/games`,
    });
  }
  const queryGames = {
    queryKey: queryKeys.session.games(sessionId),
    queryFn: getGamesBySession,
    cacheTime: 10000,
  };

  return useQuery(queryGames);
}

export function useGenerateGame(
  sessionId: string,
  config?: MutationConfig<Game, typeof generateGame>,
): MutationResult<Game, typeof generateGame> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function generateGame(payload: {
    sport: "basketball";
    homeTeamPlayersCount: number;
    awayTeamPlayersCount: number;
  }): Promise<Game> {
    return await run<Game>({
      method: "POST",
      path: `/collections/${sessionId}/games`,
      body: payload,
    });
  }
  const mutation = useMutation({
    ...config,
    mutationFn: generateGame,
    onSettled: async () =>
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.games(sessionId),
      }),
  });
  return mutation;
}
