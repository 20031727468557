import { Fragment } from "react";
import { UserAvatar } from "../avatar/UserAvatar";
import { BaseUser } from "../../lib/data/models";
import { classNames as cn } from "../../lib/utils/classNames";

export interface SDTableColumn {
  id: string;
  name: string;
  classNames?: string;
}

export interface SDTableHeaderData {
  groupId: string;
  columns: SDTableColumn[];
}

export interface SDTableData {
  rows: SDPerformer[];
}

export interface SDData {
  name: string;
  value: string | number;
}

export interface SDPerformer {
  id: string;
  user: BaseUser;
  stats: SDData[];
}

export function SDTable({ headers, data }: { headers: SDTableHeaderData; data: SDTableData }) {
  return (
    <table className="table-fixed w-full text-sm">
      <thead className="border-b border-solid border-b-gray-300">
        <tr key={headers?.groupId}>
          {headers?.columns.map(column => {
            return (
              <th className={cn("font-sans font-medium", column.classNames)} key={column.id}>
                {column.name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="pt-1">
        {data?.rows.map(row => (
          <Fragment key={row.id}>
            <tr className="block pt-1">
              <td className="w-max flex gap-1.5 items-center">
                <UserAvatar user={row.user} />
                {`${row.user.firstName} ${row.user.lastName}`}
              </td>
            </tr>
            <tr className="border-b border-solid border-b-gray-300" key={row.id}>
              {row.stats.map(stat => (
                <td key={stat.name} className="text-center">
                  {stat.value}
                </td>
              ))}
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}
