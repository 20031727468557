import { Button } from "../../components/Button";
import { LoadingSpinner } from "../../components/Spinner";
import { UserAvatar } from "../../components/avatar/UserAvatar";
import { GameEvent, PlayerWithDetails } from "../../lib/data/models";
import { usePlayersByGame } from "../../lib/data/players";
import { useGameEventsRefresher } from "../../lib/data/stats";
import { fullName, shortDateTime } from "../../lib/utils/format";
import { Nameable } from "../../lib/utils/types";
import { ErrorPage } from "../pages/ErrorPage";
import { feedMessage } from "./messages";
import { ContentContainer } from "../../components/ContentContainer";
import { TbRefresh } from "react-icons/tb";
import { useToast } from "../../components/ui/use-toast";
interface StatFeedProps {
  gameId: string;
}
export function StatFeed({ gameId }: StatFeedProps) {
  const { toast } = useToast();
  const { data: teams = { homeTeam: [], awayTeam: [] } } = usePlayersByGame(gameId);
  const { status, data, fetchNextPage, hasNextPage, refetch } = useGameEventsRefresher(gameId);
  const homeTeamPlayers = teams.homeTeam.map(p => p.id);
  const awayTeamPlayers = teams.awayTeam.map(p => p.id);
  const allPlayers = teams.homeTeam.concat(teams.awayTeam);
  const playerInTeam = (playerId: string) => allPlayers.find(p => p.id === playerId);
  const isInHomeTeam = (playerId: string) => homeTeamPlayers.includes(playerId);
  const isInAwayTeam = (playerId: string) => awayTeamPlayers.includes(playerId);

  const events = data?.pages.flat() ?? [];

  const onRefresh = async () => {
    await refetch();
    toast({ title: "Success!", description: "refreshed" });
  };
  if (status === "error") return <ErrorPage />;
  return (
    <ContentContainer>
      <div className="flex flex-col gap-4">
        <div className="heading-2 flex flex-row items-center gap-3">
          <span>play-by-play</span> <TbRefresh size="20" onClick={onRefresh} />
        </div>
        {status === "pending" ? (
          <LoadingSpinner />
        ) : (
          <>
            {events.map(event => (
              <StatCard
                key={event.id}
                player={playerInTeam(event.playerId)}
                event={event}
                teamText={
                  isInHomeTeam(event.playerId)
                    ? "Team 1"
                    : isInAwayTeam(event.playerId)
                    ? "Team 2"
                    : "N/A"
                }
              />
            ))}
            {status === "success" && hasNextPage && (
              <Button intent="light" label="see more" onClick={async () => await fetchNextPage()} />
            )}
          </>
        )}
      </div>
    </ContentContainer>
  );
}

interface StatCardProps {
  player: PlayerWithDetails | undefined;
  event: GameEvent & { logger?: Nameable };
  teamText: string;
}
export function StatCard({ player, event, teamText }: StatCardProps) {
  return player ? (
    <div className="w-full max-w-sm p-2 rounded-lg shadow-lg bg-white animate-fade">
      <div className="flex flex-row items-center gap-2">
        <UserAvatar user={player.user} />
        <div>
          <div className="text-xs">{teamText}</div>
          <div className="text-sm font-bold">{fullName(player.user)}</div>
        </div>
        {event.logger && (
          <div className="flex-1 text-xs text-right">by: {fullName(event.logger)}</div>
        )}
      </div>
      <div className="flex flex-row gap-4">
        <div>{shortDateTime(event.createdAt)}</div>
        <div>{feedMessage(event)}</div>
      </div>
    </div>
  ) : (
    <></>
  );
}
