import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../api";
import { MutationConfig, QueryConfig } from "../utils/types";
import { GameEvent, GameScore, GameStat, GameStatsByPlayer } from "./models";
import { queryKeys } from "./queryKeys";
import * as dt from "date-fns";

export type Sport = "basketball"; // if we add more
export const GameStatsBySport: Record<Sport, GameStat[]> = {
  basketball: [
    "basketball_score",
    "basketball_miss",
    "basketball_rebound",
    "basketball_assist",
    "basketball_steal",
    "basketball_turnover",
    "basketball_block",
  ],
};

export const statLabels: Record<GameStat, string> = {
  basketball_assist: "AST",
  basketball_miss: "MISS",
  basketball_rebound: "RB",
  basketball_score: "MAKE",
  basketball_steal: "ST",
  basketball_turnover: "TO",
  basketball_block: "BLK",
};

export function useGameEvents(gameId: string) {
  const { run } = useApi();
  const getGameEvents = async (skip: number) =>
    await run<GameEvent[]>({
      path: `/games/${gameId}/events`,
      method: "GET",
      params: {
        skip,
        size: 20,
      },
    });
  const query = {
    queryKey: queryKeys.games.events(gameId),
    queryFn: async (params: any) => await getGameEvents(params.pageParam.skip),
    getNextPageParam: (lastPage: any[], pages: any[]) =>
      pages.length > 0 && lastPage.length === 0
        ? undefined
        : {
            skip: pages.flat().length,
          },
    initialPageParam: {
      skip: 0,
    },
  };
  return useInfiniteQuery(query);
}

const isWithinMinutes = (date: string | Date, minutes: number) =>
  dt.isWithinInterval(date, {
    start: dt.sub(new Date(), { minutes }),
    end: new Date(),
  });
export function useGameEventsRefresher(gameId: string) {
  const queryClient = useQueryClient();
  const gameEventsQuery = useGameEvents(gameId);
  const { status, data, refetch } = gameEventsQuery;

  // long poll for results
  const events = data?.pages.flat() ?? [];
  const { isSuccess } = useQuery({
    queryKey: [],
    queryFn: async () => await refetch(),
    enabled: status === "success" && events.some(e => isWithinMinutes(e.createdAt, 5)), // TODO: this should be false if the game is complete
    refetchOnMount: true,
    refetchInterval: 5000,
  });
  if (isSuccess) {
    if (data?.pages[0].some(e => e.stat === "basketball_score")) {
      queryClient.invalidateQueries({ queryKey: queryKeys.games.score(gameId) });
    }
    // queryClient.invalidateQueries({ queryKey: queryKeys.games.stats(gameId) });
  }
  return { ...gameEventsQuery, isSuccess };
}

export function useCreateGameEvents(
  gameId: string,
  config?: MutationConfig<GameEvent, typeof createGameEvent>,
) {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function createGameEvent(payload: { playerId: string; stat: GameStat }) {
    return await run<GameEvent>({
      method: "POST",
      path: `/games/${gameId}/events`,
      body: payload,
    });
  }

  const mutation = useMutation({
    ...config,
    mutationFn: createGameEvent,
    onSettled: async (_, __, variables) => {
      if (variables.stat === "basketball_score") {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.games.score(gameId),
        });
      }
    },
  });
  return mutation;
}

export function useGameStats(gameId: string, config?: QueryConfig<GameStatsByPlayer>) {
  const { run } = useApi();
  const fn = async () =>
    await run<GameStatsByPlayer>({
      path: `/games/${gameId}/stats`,
      method: "GET",
    });
  const query = {
    ...config,
    queryKey: queryKeys.games.stats(gameId),
    queryFn: fn,
  };
  return useQuery(query);
}

export function useGameScore(gameId: string, config?: QueryConfig<GameScore>) {
  const { run } = useApi();
  const getScore = async () =>
    await run<GameScore>({
      path: `/games/${gameId}/stats/score`,
      method: "GET",
    });
  const query = {
    ...config,
    queryKey: queryKeys.games.score(gameId),
    queryFn: getScore,
    staleTime: 5000,
  };
  return useQuery(query);
}
