import { Divider } from "../../components/Divider";
import { GrAddCircle, GrFormSearch, GrSubtractCircle } from "react-icons/gr";
import { useState } from "react";
import { CreateSessionPlayerForm } from "./CreateSessionPlayerForm";
import { SearchSessionPlayers } from "./SearchSessionPlayers";
import { UserAvatar } from "../../components/avatar/UserAvatar";
import { Button } from "../../components/Button";
import { useRemovePlayerFromSession, useSession } from "../../lib/data/session";
import { usePlayersBySession } from "../../lib/data/players";

type View = "list" | "new" | "search";

export function EditSessionPlayers({ sessionId }: { sessionId: string }) {
  const [view, setView] = useState<View>("list");
  const { data: players = [] } = usePlayersBySession(sessionId);
  const { data: session } = useSession(sessionId);
  const { mutateAsync: removePlayer } = useRemovePlayerFromSession(sessionId);

  if (!session) {
    return <div>Not Found</div>;
  }

  const description = () => {
    switch (view) {
      case "list":
        return <div className="text-lg">Players ({players.length})</div>;
      case "search":
        return <div className="text-lg">Search Players</div>;
      case "new":
        return (
          <div>
            <div className="text-lg">Add New Player</div>
            <div className="text-sm italic">Fill in the information below to add a new player.</div>
          </div>
        );
    }
  };

  const body = () => {
    switch (view) {
      case "list":
        return (
          <div className="overflow-y-scroll max-h-[400px] hover:shadow-inner">
            <div className="flex flex-col items-start gap-2">
              {players.map(player => (
                <div
                  key={player.id}
                  className="flex flex-row justify-between items-center w-full hover:bg-grey-light pr-8"
                >
                  <UserAvatar key={player.id} user={player.user} showFullName />
                  <IconWithConfirmation
                    confirmationText="remove"
                    onConfirm={async () => await removePlayer(player.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "search":
        return (
          <SearchSessionPlayers sessionId={sessionId} existingPlayers={players.map(p => p.id)} />
        );
      case "new":
        return (
          <div className="animate-fade">
            <CreateSessionPlayerForm
              sessionId={sessionId}
              onComplete={() => setView("list")}
              onCancel={() => setView("list")}
            />
          </div>
        );
    }
  };

  const icons = (
    <div className="flex flex-row gap-2 items-center">
      <GrFormSearch className="w-[30px] h-[30px] cursor-pointer" onClick={() => toggle("search")} />
      <GrAddCircle className="w-[24px] h-[24px] cursor-pointer" onClick={() => toggle("new")} />
    </div>
  );

  const toggle = (newView: View) => {
    if (view === newView) {
      setView("list");
    } else {
      setView(newView);
    }
  };

  return (
    <div>
      <div className="heading-3">{new Date(session.startDate).toLocaleString()}</div>
      <div className="text-sm pb-3 text-grey-dark">{session.title}</div>
      <Divider />
      <div className="mt-6">
        <div className="flex flex-row justify-between items-center mb-4">
          {description()}
          {icons}
        </div>
        {body()}
      </div>
    </div>
  );
}

function IconWithConfirmation({
  confirmationText,
  onConfirm,
}: {
  confirmationText: string;
  onConfirm: () => void;
}) {
  const [clicked, setClicked] = useState<boolean>(false);
  return !clicked ? (
    <GrSubtractCircle
      onClick={() => setClicked(true)}
      className="w-[24px] h-[24px] cursor-pointer animate-fade"
    />
  ) : (
    <Button
      className="animate-fade"
      onClick={onConfirm}
      size="sm"
      intent="danger"
      label={confirmationText}
    />
  );
}
