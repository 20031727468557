import { runFn as run } from "../api";
import { AppUser } from "./models";

export type UpsertUserPayload = {
  authenticatorId: string;
  email: string;
  firstName: string;
  lastName: string;
  imageUrl?: string;
};
export async function upsertUser(payload: UpsertUserPayload): Promise<AppUser> {
  return await run<AppUser>({
    path: `/users`,
    method: "POST",
    body: payload,
  });
}
