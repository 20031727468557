import { useParams } from "react-router-dom";
import { ContentContainer } from "../../components/ContentContainer";
import { PageContainer } from "../../components/PageContainer";
import { SessionGamesSection } from "../games/SessionGamesSection";
import { SessionPageHeader } from "../sessions/SessionPageHeader";
import { Dialog, DialogContent, DialogHeader } from "../../components/ui/dialog";
import { useState } from "react";
import { EditSessionPlayers } from "../sessions/EditSessionPlayers";
import { useGamesBySession, useGenerateGame } from "../../lib/data/game";
import { NotFoundPage } from "./NotFound";
import { useSession } from "../../lib/data/session";
import { ErrorContent } from "./ErrorPage";
import { LoadingSpinner } from "../../components/Spinner";
import { FolderTabs, TabsConfig } from "../../components/ui/folder-tabs";

// wrapper to ensure id is present
export function SessionPage() {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    return <NotFoundPage returnRoute="/dashboard" />;
  }
  return (
    <PageContainer>
      <SessionPageContent sessionId={id} />
    </PageContainer>
  );
}

export function SessionPageContent({ sessionId }: { sessionId: string }) {
  const [open, setOpen] = useState<boolean>(false);
  const session = useSession(sessionId);
  const { data: games = [] } = useGamesBySession(sessionId);
  const { mutateAsync: generateGameForSession } = useGenerateGame(sessionId);

  const onGenerate = async () => {
    try {
      await generateGameForSession({
        sport: "basketball",
        awayTeamPlayersCount: 5,
        homeTeamPlayersCount: 5,
      });
    } catch (e) {
      console.error(`error ocurred when generating game`, e);
    }
  };

  const modal = (
    <DialogContent className="bg-white">
      <DialogHeader>
        <div className="heading-1">Session Players</div>
      </DialogHeader>
      <hr className="text-grey-dark" />
      <EditSessionPlayers sessionId={sessionId} />
    </DialogContent>
  );

  const tabsConfig: TabsConfig = {
    ariaLabel: "session tab list",
    defaultValue: "GAMES",
    tabs: [
      {
        value: "GAMES",
        content: <SessionGamesSection games={games} />,
      },
      {
        value: "STATS",
        content: <div>stats...</div>,
      },
    ],
  };

  switch (session.status) {
    case "pending":
      return <LoadingSpinner />;
    case "error":
      return <ErrorContent message="Unable to find session. Please try again later." />;
    case "success":
      return (
        <>
          <ContentContainer>
            <Dialog open={open} onOpenChange={setOpen}>
              {modal}
              <div className="flex flex-col justify-center">
                <SessionPageHeader sessionId={sessionId} generateGameForSession={onGenerate} />
              </div>
            </Dialog>
          </ContentContainer>
          <FolderTabs tabsConfig={tabsConfig} />
        </>
      );
  }
}
