import { classNames } from "../../lib/utils/classNames";
import { fullName } from "../../lib/utils/format";
import { Nameable } from "../../lib/utils/types";
import { UserAvatar } from "./UserAvatar";

interface AvatarStackProps {
  users: (Nameable & { id: string; imageUrl: string })[];
  showNumberOfUsers?: number;
  className?: string;
  onClick?: () => void;
}

export function AvatarStack({
  users,
  showNumberOfUsers = 5,
  className = "",
  onClick,
}: AvatarStackProps) {
  const usersToShow = users.slice(0, showNumberOfUsers);
  const countOfHiddenUsers = users.length - usersToShow.length;
  return (
    <div className={classNames("flex flex-row items-center", className)}>
      {usersToShow.map((user, index) => (
        <UserAvatar
          user={user}
          className={`w-[32px] h-[32px] sm:w-[40px] sm:h-[40px]`}
          key={user.id}
          style={{
            zIndex: usersToShow.length - index,
            marginRight: "-14px",
          }}
          title={fullName(user)}
          onClick={onClick}
        />
      ))}
      {countOfHiddenUsers > 0 && (
        <span className="text-sm text-grey-darker ms-4 relative">+{countOfHiddenUsers}</span>
      )}
    </div>
  );
}
