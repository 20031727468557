import { Game } from "../../lib/data/models";
import { GameCard } from "./GameCard";

interface GameListProps {
  games: Game[];
}

export function GameList({ games }: GameListProps) {
  return (
    <div className="flex flex-col gap-3 mt-3 max-w-xl">
      {games.map((game, index) => (
        <GameCard key={game.id} game={game} title={`Game ${index + 1}`} />
      ))}
    </div>
  );
}
