import { useState } from "react";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/Button";
import { fullName } from "../../lib/utils/format";
import { UserAvatar } from "../../components/avatar/UserAvatar";
import { useSearchPlayers } from "../../lib/data/players";
import { useAddPlayerToSession } from "../../lib/data/session";

interface SearchSessionPlayersProps {
  sessionId: string;
  existingPlayers: string[];
}
export function SearchSessionPlayers({ sessionId, existingPlayers }: SearchSessionPlayersProps) {
  const [search, setSearch] = useState<string>("");
  const { data: players = [], refetch } = useSearchPlayers({ search }, { enabled: false });
  const { mutateAsync: addPlayerToSession } = useAddPlayerToSession(sessionId);

  const onSearch = async () => {
    await refetch();
  };

  const isAlreadyInSession = (playerId: string) => existingPlayers.includes(playerId);

  const playersList = (
    <div className="flex flex-col gap-4 mt-8 max-h-[300px] overflow-scroll">
      {players.map(player => (
        <div key={player.id} className="flex flex-row gap-2 justify-between">
          <div className="flex flex-row gap-3 items-center">
            <UserAvatar user={player.user} />
            <div className="flex flex-col">
              <div>{fullName(player.user)}</div>
              <div>{player.user.email}</div>
            </div>
          </div>
          <Button
            size="sm"
            className="w-[100px]"
            intent="green"
            label={isAlreadyInSession(player.id) ? `in session` : `add`}
            disabled={isAlreadyInSession(player.id)}
            onClick={async () => {
              await addPlayerToSession(player.id);
            }}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <Input
          className="border-0 bg-grey-light"
          placeholder="Search..."
          onChange={e => setSearch(e.target.value)}
        />
        <Button
          // disabled={search.length < 3} // TODO: lock this down better
          intent={"green"}
          size="sm"
          label="search"
          onClick={onSearch}
        />
      </div>
      {playersList}
    </div>
  );
}
