import { VariantProps, cva } from "class-variance-authority";
import { GameScore as Model } from "../../lib/data/models";

const variants = cva("heavy", {
  variants: {
    size: {
      sm: ["text-[30px]"],
      md: ["text-[45px]"],
      lg: ["text-[60px]"],
      xl: ["text-[75px]"],
    },
  },
});

interface Props extends VariantProps<typeof variants> {
  score: Model;
}

export function GameScore({ score, size }: Props) {
  return (
    <div className="flex flex-row gap-2 items-center justify-center">
      <span className={variants({ size })}>{score?.homeTeam}</span>
      <span>vs.</span>
      <span className={variants({ size })}>{score?.awayTeam}</span>
    </div>
  );
}
