import { GameList } from "./GameList";
import { Game } from "../../lib/data/models";
import { ContentContainer } from "../../components/ContentContainer";

export function SessionGamesSection({ games }: { games: Game[] }) {
  return (
    <ContentContainer className="pt-4">
      <GameList games={games} />
    </ContentContainer>
  );
}
