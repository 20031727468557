import { ContentContainer } from "../../components/ContentContainer";
import { Collection } from "../../lib/data/models";
import { SessionCard } from "./SessionCard";

export function SessionsList({ sessions }: { sessions: Collection[] }) {
  return (
    <ContentContainer className="pt-4">
      {sessions.map(session => (
        <SessionCard session={session} key={session.id} />
      ))}
    </ContentContainer>
  );
}
