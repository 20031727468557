import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";
import { classNames } from "../lib/utils/classNames";
import { LoadingSpinner } from "./Spinner";

// TODO: change these colors to style guide
const buttonVariants = cva("px-4 py-2", {
  variants: {
    intent: {
      outline: ["bg-white", "text-black", "border", "border-black"],
      circle: [
        "rounded-full",
        "bg-primary",
        "text-white",
        "h-[50px]",
        "w-[50px]",
        "text-md",
        "text-center",
      ],
      primary: ["bg-blue-500", "hover:bg-blue-600", "text-white"],
      danger: ["bg-error", "text-white"],
      yellow: ["bg-yellow", "hover:bg-yellow-light", "border", "border-black"],
      green: ["bg-green", "hover:bg-green-light", "border", "border-black", "text-white"],
      light: ["bg-gray-100", "hover:bg-gray-300", "text-blue-500"],
      disabled: ["bg-grey", "text-grey-dark", "cursor-not-allowed"],
    },
    size: {
      sm: ["text-sm", "py-2", "px-4"],
      md: ["text-md", "py-2", "px-8"],
      lg: ["text-lg", "py-4", "px-8"],
      xl: ["text-xl", "py-5", "px-10"],
    },
  },
  defaultVariants: {
    intent: "outline",
    size: "md",
  },
});

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  label: string;
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, label, intent, size, isLoading = false, ...props }, ref) => (
    <button
      ref={ref}
      className={classNames(
        buttonVariants({ intent: props.disabled ? "disabled" : intent, size }),
        className,
      )}
      {...props}
    >
      {isLoading && (
        <div className="absolute m-auto">
          <LoadingSpinner className="w-[30px] h-[30px] float-left" />
        </div>
      )}
      {label}
    </button>
  ),
);
Button.displayName = "Button";
export { Button };
