import { LoadingSpinner } from "../../components/Spinner";
import { TitledSection } from "../../components/TitledSection";
import { SessionsList } from "./SessionsList";
import { Button } from "../../components/Button";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { CreateSessionForm } from "./CreateSessionForm";
import * as dt from "date-fns";
import { useSessions } from "../../lib/data/session";
import { FolderTabs, TabsConfig } from "../../components/ui/folder-tabs";
import { ContentContainer } from "../../components/ContentContainer";

export function SessionsSection() {
  const [open, setOpen] = useState<boolean>(false);
  const {
    isPending,
    refetch,
    error,
    data: sessions,
  } = useSessions({
    startDate: dt.sub(new Date(), { days: 7 }).toISOString(),
  });

  if (isPending) return <LoadingSpinner />;
  if (error) return <div>An error occurred</div>;

  const createButton = (
    <DialogTrigger asChild>
      <Button className="w-full md:max-w-xs" intent="yellow" label="add a session +" />
    </DialogTrigger>
  );

  const onCloseModal = () => {
    setOpen(false);
    refetch();
  };

  const modal = (
    <DialogContent className="w-full sm:max-w-md bg-white-custom">
      <DialogHeader className="border-b border-grey pb-4">
        <DialogTitle className="text-left text-2xl font-Altivo font-black uppercase">
          Add Session
        </DialogTitle>
      </DialogHeader>
      <CreateSessionForm closeModal={onCloseModal} />
    </DialogContent>
  );

  const tabsConfig: TabsConfig = {
    ariaLabel: "Sessions tab list",
    defaultValue: "ONGOING",
    tabs: [
      {
        value: "ONGOING",
        // TODO: filter on ongoing
        content: <SessionsList sessions={sessions} />,
      },
      {
        value: "FINISHED",
        // TODO: filter on finished
        content: "finished...",
      },
    ],
  };

  return (
    <>
      <ContentContainer>
        <Dialog open={open} onOpenChange={setOpen}>
          <TitledSection title="Sessions">
            {modal}
            {createButton}
          </TitledSection>
        </Dialog>
      </ContentContainer>
      <FolderTabs tabsConfig={tabsConfig} />
    </>
  );
}
