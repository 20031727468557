import { Nameable } from "../../lib/utils/types";
import { AvatarList } from "./AvatarList";
import { AvatarStack } from "./AvatarStack";

interface Props {
  users: (Nameable & { id: string; imageUrl: string })[];
  open: boolean;
  onClick: () => void;
  className?: string;
}
export function AvatarListToStack({ users, open, className = "", onClick }: Props) {
  const content = open ? (
    <AvatarList className="animate-fade cursor-pointer" users={users} onClick={onClick} />
  ) : (
    <AvatarStack className="animate-fade cursor-pointer" users={users} onClick={onClick} />
  );
  return <div className={className}>{content}</div>;
}
