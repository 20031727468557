import { ToastAction } from "../../components/ui/toast";
import { useToast } from "../../components/ui/use-toast";
import { GameStat, PlayerWithDetails } from "../../lib/data/models";
import { useCreateGameEvents } from "../../lib/data/stats";
import { StatButton } from "./StatButton";
import { getMessage } from "./messages";

interface StatButtonListProps {
  gameId: string;
  disabled: boolean;
  stats: GameStat[];
  selectedPlayerId: string;
  player?: PlayerWithDetails;
}

export function StatButtonList({
  stats,
  disabled,
  gameId,
  selectedPlayerId,
  player,
}: StatButtonListProps) {
  const { mutateAsync: createGameEvent } = useCreateGameEvents(gameId);
  const { toast } = useToast();
  const onClick = async (stat: GameStat) => {
    try {
      await createGameEvent({ playerId: selectedPlayerId, stat });
      toast({
        title: `Success!`,
        description: `Success! ${getMessage(stat, player)}`,
      });
    } catch (e) {
      toast({
        variant: "destructive",
        title: `Error`,
        description: `Unable to log stat ${stat} for ${selectedPlayerId}`,
        action: (
          <ToastAction onClick={() => console.log(`TODO: try again`)} altText="Try again!">
            Try Again
          </ToastAction>
        ),
      });
    }
  };
  const makeButton = (stat: GameStat) => (
    <StatButton key={stat} disabled={disabled} stat={stat} onClick={() => onClick(stat)} />
  );

  return (
    <div className="max-w-sm flex flex-wrap justify-center px-2 gap-1">{stats.map(makeButton)}</div>
  );
}
