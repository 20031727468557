import { classNames } from "../lib/utils/classNames";

export const LoadingSpinner = ({ className = "" }: { className?: string }) => (
  <div
    className={classNames(
      "border-grey h-20 w-20 animate-spin rounded-full border-8 border-t-green m-auto",
      className,
    )}
  />
);
