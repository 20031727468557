import { UserAvatar } from "../../components/avatar/UserAvatar";
import { PlayerWithDetails } from "../../lib/data/models";
import { classNames } from "../../lib/utils/classNames";

export function PlayerList({
  players,
  selectedPlayerId,
  onSelect,
}: {
  players: PlayerWithDetails[];
  selectedPlayerId: string;
  onSelect: (playerId: string) => void;
}) {
  return (
    <div className="flex flex-col gap-6">
      {players.map(player => (
        <div
          key={player.id}
          className={classNames(
            selectedPlayerId === player.id ? "outline outline-4 outline-green" : "",
            "rounded-full outline-offset-2",
          )}
        >
          <UserAvatar
            user={player.user}
            onClick={() => onSelect(player.id)}
            className="cursor-pointer"
          />
        </div>
      ))}
    </div>
  );
}
