import { Game } from "../../lib/data/models";
import { LoadingSpinner } from "../../components/Spinner";
import { useState } from "react";
import { AvatarListToStack } from "../../components/avatar/AvatarListToStack";
import { Link } from "react-router-dom";
import { usePlayersByGame } from "../../lib/data/players";
import { useGameScore } from "../../lib/data/stats";
import { GameScore } from "./GameScore";

// TODO: Add game title
export function GameCard({ game, title }: { game: Game; title: string }) {
  const [open, setOpen] = useState<boolean>(false);
  const { data } = usePlayersByGame(game.id);
  const { data: score = { homeTeam: 0, awayTeam: 0 } } = useGameScore(game.id);

  if (!data || !data.homeTeam || !data.awayTeam) {
    return <LoadingSpinner />;
  }

  const scoreView = <GameScore score={score} size="sm" />;

  return (
    <div className="border-xl border-black">
      <div className="border p-4">
        <div className="mb-4">
          <span className="heading-2">{title}</span>
        </div>
        <div className="my-4">{open && scoreView}</div>
        <div className="flex flex-row justify-between bg-white-custom">
          <div className="flex flex-col gap-3 w-1/2 items-center">
            <div className="text-center heading-3">Team 1</div>
            <AvatarListToStack
              open={open}
              onClick={() => setOpen(open => !open)}
              users={data.homeTeam.map(player => player.user)}
            />
          </div>
          {!open && scoreView}
          <div className="flex flex-col gap-3 w-1/2 items-center">
            <div className="text-center heading-3">Team 2</div>
            <AvatarListToStack
              open={open}
              onClick={() => setOpen(open => !open)}
              users={data.awayTeam.map(player => player.user)}
            />
          </div>
        </div>
      </div>
      <Link to={`/game/${game.id}`}>
        <div className="bg-green-emerald w-full rounded-b-xl px-4 text-right text-white cursor-pointer">
          View / Edit
        </div>
      </Link>
    </div>
  );
}
