import { SignOutButton, SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import { initials } from "../lib/utils/format";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { GoogleSignInButton } from "../views/auth/SignIn";
import { Avatar, AvatarImage } from "./ui/avatar";
import { AvatarFallback } from "@radix-ui/react-avatar";
import { Link, useLocation } from "react-router-dom";
import { LogoStacked } from "./svgr";

export function Header() {
  return (
    <div className="sticky top-0 z-50 p-2.5 md:px-5 sm:w-full bg-white min-w-fit flex flex-row justify-between border-b border-[#f1f5f9] items-center">
      <div className="items-center">
        <Link to="/">
          <LogoStacked className="h-[36px] md:h-[54px] fill-green" aria-label="logo" />
        </Link>
      </div>
      <div className="flex flex-row gap-4 align-center">
        <UserIcon />
      </div>
    </div>
  );
}

function UserIcon() {
  const { user } = useUser();
  const { pathname } = useLocation();
  const userInitials = initials(user?.fullName ?? "");

  const avatarDropdown = (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar>
          <AvatarImage src={user?.imageUrl} />
          <AvatarFallback>{userInitials}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white-custom">
        <DropdownMenuLabel>{user?.fullName}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <SignOutButton>sign out</SignOutButton>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
  return (
    <div>
      <SignedIn>
        <div className="flex flex-row gap-2 z-10">{avatarDropdown}</div>
      </SignedIn>
      <SignedOut>
        <GoogleSignInButton previousUrl={pathname} />
      </SignedOut>
    </div>
  );
}
