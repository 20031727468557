import { Link, useParams, useSearchParams } from "react-router-dom";
import { PageContainer } from "../../components/PageContainer";
import { Button } from "../../components/Button";
import { useGameScore } from "../../lib/data/stats";
import { useGame } from "../../lib/data/game";
import { NotFoundPage } from "./NotFound";
import { FolderTabs, TabsConfig } from "../../components/ui/folder-tabs";
import { StatFeed } from "../stats/StatFeed";
import { ErrorContent } from "./ErrorPage";
import { LoadingSpinner } from "../../components/Spinner";
import { GameScore } from "../games/GameScore";
import { GameStatsTable } from "../stats/GameStatsTable";

export function GamePage() {
  const { id } = useParams();
  if (!id) return <NotFoundPage returnRoute="/dashboard" />;
  return (
    <PageContainer>
      <GamePageContent gameId={id} />
    </PageContainer>
  );
}

const TABS = ["stats", "feed"];
function GamePageContent({ gameId }: { gameId: string }) {
  // handle tab param in url
  const [param] = useSearchParams();
  const tabParam = param.get("tab") ?? "";
  const defaultTab = TABS.includes(tabParam) ? tabParam : "stats";
  const { status, data: game } = useGame(gameId);
  const { data: score = { homeTeam: 0, awayTeam: 0 } } = useGameScore(gameId, {
    enabled: status === "success",
  });

  if (status === "error") {
    return <ErrorContent message="Unable to find game. Please try again later." />;
  } else if (status === "pending") {
    return <LoadingSpinner />;
  }

  const tabsConfig: TabsConfig = {
    ariaLabel: "Game tabs list",
    defaultValue: defaultTab,
    tabs: [
      {
        value: "stats",
        content: <GameStatsTable gameId={gameId} />,
      },
      {
        value: "feed",
        content: <StatFeed gameId={gameId} />,
      },
    ],
  };

  return (
    <>
      <div className="p-3">
        {game?.collectionId && (
          <Link to={`/session/${game.collectionId}`}>
            <Button className="float-left" intent={"yellow"} label="back to session" />
          </Link>
        )}
        <Link to={`/game/${gameId}/stats`}>
          <Button className="float-right" intent={"yellow"} label="stat keeping mode" />
        </Link>
      </div>
      <div className="mt-[2rem]">
        <GameScore score={score} size="lg" />
      </div>
      <FolderTabs className="" tabsConfig={tabsConfig} />
    </>
  );
}
