import * as dt from "date-fns";
import { GameStat } from "../data/models";
import { Nameable } from "./types";

export const initials = (fullName: string) =>
  fullName
    .split(" ")
    .map(s => s[0]?.toUpperCase() ?? "")
    .join("");

export const fullName = ({ firstName, lastName }: Nameable): string => {
  return `${firstName} ${lastName}`;
};

export const statLabels: Record<GameStat, string> = {
  basketball_assist: "AST",
  basketball_miss: "MISS",
  basketball_rebound: "RB",
  basketball_score: "MAKE",
  basketball_steal: "ST",
  basketball_turnover: "TO",
  basketball_block: "BLK",
};

export function shortDateTime(dateString: string | Date) {
  return dt.format(new Date(dateString), "M/dd/yy • hh:mm a");
}

export function percentageString(num: number, decimals: number = 1) {
  const numeric = (num * 100).toFixed(decimals);
  return `${numeric}`;
}
