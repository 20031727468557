import { useState } from "react";
import { Button } from "../../components/Button";
import { GameStat } from "../../lib/data/models";
import { statLabels } from "../../lib/data/stats";

interface StatButtonProps {
  disabled: boolean;
  stat: GameStat;
  onClick: () => void;
}

export function StatButton({ disabled, stat, onClick }: StatButtonProps) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const on = async () => {
    setLoading(true);
    try {
      await onClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      className="flex-1 rounded-lg py-5 m-1 min-w-[120px]"
      isLoading={isLoading}
      disabled={disabled || isLoading}
      intent="yellow"
      label={statLabels[stat]}
      onClick={on}
    />
  );
}
