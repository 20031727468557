import { ComponentProps } from "react";
import { classNames } from "../../lib/utils/classNames";
import { fullName, initials } from "../../lib/utils/format";
import { Nameable } from "../../lib/utils/types";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export function UserAvatar({
  user,
  showFullName = false,
  ...props
}: ComponentProps<typeof Avatar> & {
  user: Nameable & { id: string; imageUrl: string };
  showFullName?: boolean;
}) {
  return (
    <span className={classNames(showFullName ? "flex flex-row gap-2 items-center" : "")}>
      <Avatar title={fullName(user)} {...props}>
        <AvatarImage src={user.imageUrl} />
        <AvatarFallback className={classNames("bg-green border border-black text-white")}>
          {initials(fullName(user))}
        </AvatarFallback>
      </Avatar>
      {showFullName && <span>{fullName(user)}</span>}
    </span>
  );
}
