import { Navigate, createBrowserRouter } from "react-router-dom";
import Dashboard from "../../views/pages/Dashboard";
import { AuthenticateWithRedirectCallback, SignedIn, SignedOut } from "@clerk/clerk-react";
import SignedInPage from "../../views/pages/SignedInPage";
import { LandingPage } from "../../views/pages/LandingPage";
import { GoogleSignIn } from "../../views/auth/SignIn";
import { SessionPage } from "../../views/pages/SessionPage";
import { GamePage } from "../../views/pages/GamePage";
import ScrollToTop from "../../components/ScrollToTop";
import { GameStatKeeperPage } from "../../views/pages/GameStatKeeperPage";
import { NotFoundPage } from "../../views/pages/NotFound";
import { ErrorPage } from "../../views/pages/ErrorPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <LandingPage />
      </>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/session/:id",
    element: (
      <ProtectedRoute>
        <SessionPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/game/:id",
    element: (
      <ProtectedRoute>
        <GamePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/game/:id/stats",
    element: (
      <ProtectedRoute>
        <GameStatKeeperPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sign-in",
    element: <GoogleSignIn />,
  },
  { path: "/signed-in", element: <SignedInPage /> },
  {
    path: "/sso-callback",
    element: <AuthenticateWithRedirectCallback />,
  },
  {
    path: "/404",
    element: <NotFoundPage />,
  },
  {
    path: "/500",
    element: <ErrorPage />,
  },
]);

function ProtectedRoute({
  children,
  fallback = "/sign-in",
}: {
  children: JSX.Element;
  fallback?: string;
}) {
  return (
    <>
      <ScrollToTop />
      <SignedIn>{children}</SignedIn>
      <SignedOut>
        <Navigate to={fallback} />
      </SignedOut>
    </>
  );
}
