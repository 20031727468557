import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/Button";
import { classNames } from "../../lib/utils/classNames";
import { LoadingSpinner } from "../../components/Spinner";
import { useCreatePlayer } from "../../lib/data/players";

export function CreateSessionPlayerForm({
  sessionId: collectionId,
  onComplete,
  onCancel,
}: {
  sessionId: string;
  onComplete: () => void;
  onCancel: () => void;
}) {
  const { mutateAsync: createPlayer } = useCreatePlayer({
    onSuccess: onComplete,
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const formSchema = z.object({
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    nickname: z.string().optional(),
    email: z.string().email().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      await createPlayer({ ...form.getValues(), collectionId });
    } catch (e) {
      console.error(`error occurred while creating player`, e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames(isLoading ? "opacity-50" : "")}>
      <Form {...form}>
        <form autoComplete="off" onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="First Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Last Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="email" placeholder="Email Address (optional)" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-row justify-between">
            <Button intent="outline" disabled={isLoading} onClick={onCancel} label="Cancel" />
            {isLoading && <LoadingSpinner className="w-[50px] h-[50px]" />}
            <Button intent="green" disabled={isLoading} onClick={onSubmit} label="Add New Player" />
          </div>
        </form>
      </Form>
    </div>
  );
}
