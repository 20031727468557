import { Link } from "react-router-dom";
import { Collection } from "../../lib/data/models";
import * as dt from "date-fns";
import { AvatarStack } from "../../components/avatar/AvatarStack";
import { LoadingSpinner } from "../../components/Spinner";
import { BasketballDunkYellow } from "../../components/svgr";
import { useGamesBySession } from "../../lib/data/game";
import { usePlayersBySession } from "../../lib/data/players";

export function SessionCard({ session }: { session: Collection }) {
  const { isPending: isGamesPending, data: games = [] } = useGamesBySession(session.id);
  const { isPending: isPlayersPending, data: playersFromCollection = [] } = usePlayersBySession(
    session.id,
  );

  const users = playersFromCollection.map(p => p.user);
  const dateString = dt.format(new Date(session.startDate), "M/dd/yy • hh:mm a");

  return (
    <div className="rounded-t-md shadow-lg max-w-md bg-grey-light">
      <Link to={`/session/${session.id}`} key={session.id}>
        <div className="w-full p-3">
          <div className="flex flex-row gap-3">
            <div className="flex flex-col">
              <BasketballDunkYellow aria-label="basketball dunk icon" width={75} />
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="font-Altivo font-black text-lg md:text-2xl">{dateString}</h3>
              <div>{session.title}</div>
              {isPlayersPending ? <LoadingSpinner /> : <AvatarStack users={users} />}
            </div>
          </div>
        </div>
        {/* TODO: filter games by game state */}
        <div className="text-right text-white bg-grey-dark rounded-b-md p-1 px-2">
          {!isGamesPending && <span>{games.length} game(s)</span>}
        </div>
      </Link>
    </div>
  );
}
