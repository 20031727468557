import { useUser } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UpsertUserPayload, upsertUser } from "../../lib/data/user";
import { useEffect } from "react";
import { PageContainer } from "../../components/PageContainer";

/**
 * This page serves two functions:
 * 1. upserts the user into the db
 * 2. redirects the user to the previous page that they were at, prior to signing in.
 */
export default function SignedInPage() {
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get("redirect"); // use this redirect to send them AFTER user is upserted
  const { user } = useUser();
  if (!user || !user.firstName || !user.lastName || !user.primaryEmailAddress?.emailAddress) {
    console.log(`invalid user`, user);
    return <>Invalid user.</>;
  }

  const userPayload: UpsertUserPayload = {
    authenticatorId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.primaryEmailAddress.emailAddress,
    imageUrl: user.imageUrl,
  };

  function UpsertUserHandler({ user }: { user: UpsertUserPayload }) {
    const navigate = useNavigate();
    const query = {
      queryKey: [`users`, user.authenticatorId],
      queryFn: async () => await upsertUser(user),
      cacheTime: 10000,
    };
    const { status } = useQuery(query);

    useEffect(() => {
      if (status === "success") {
        navigate(redirectPath ?? "/");
      }
    }, [status, navigate]);
    return <></>;
  }

  return (
    <PageContainer>
      <div className="text-md">
        <UpsertUserHandler user={userPayload} />
      </div>
    </PageContainer>
  );
}
