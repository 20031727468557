import { ReactNode } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Toaster } from "./ui/toaster";

export function PageContainer({ children }: { children: ReactNode }) {
  return (
    <div className="w-full">
      <Header />
      <div className="min-h-screen pb-12">{children}</div>
      <Toaster />
      <Footer />
    </div>
  );
}
