enum Prefix {
  SESSIONS = "sessions",
  GAMES = "games",
  PLAYERS = "players",
  STATS = "stats",
}

export const queryKeys = {
  session: {
    detail: (sessionId: string) => [Prefix.SESSIONS, sessionId] as const,
    all: [Prefix.SESSIONS] as const,
    games: (sessionId: string) => [Prefix.SESSIONS, sessionId, Prefix.GAMES] as const,
    players: (sessionId: string) => [Prefix.SESSIONS, sessionId, Prefix.PLAYERS],
  },
  players: {
    detail: (playerId: string) => [Prefix.PLAYERS, playerId] as const,
    search: (search: string, limit: number) => [Prefix.PLAYERS, "search", search, limit] as const,
  },
  games: {
    detail: (gameId: string) => [Prefix.GAMES, gameId] as const,
    players: (gameId: string) => [Prefix.GAMES, gameId, Prefix.PLAYERS] as const,
    stats: (gameId: string) => [Prefix.GAMES, gameId, Prefix.STATS],
    score: (gameId: string) => [Prefix.GAMES, gameId, "score"],
    events: (gameId: string) => [Prefix.GAMES, gameId, "events"],
  },
};
