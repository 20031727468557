import { GameStatsByPlayer, PlayerWithDetails, StatAggregate } from "../../../lib/data/models";
import { Sport } from "../../../lib/data/stats";
import { percentageString } from "../../../lib/utils/format";
import { SDData, SDPerformer, SDTableColumn, SDTableData, SDTableHeaderData } from "../SDTable";

export const basketballGameStatColumns: SDTableColumn[] = [
  {
    id: "SM/SA",
    name: "SM/SA",
    // NOTE: Only pass Tailwind classes
    classNames: "min-w-16 w-16 sm:w-auto",
  },
  {
    id: "FG%",
    name: "FG%",
  },
  {
    id: "RB",
    name: "RB",
  },
  {
    id: "AST",
    name: "AST",
  },
  {
    id: "ST",
    name: "ST",
  },
  {
    id: "BLK",
    name: "BLK",
  },
  {
    id: "TO",
    name: "TO",
  },
];

const statColumns = [
  //   "games_played",
  "shots_made_shots_attempted",
  "field_goal_percentage",
  "rebound",
  "assist",
  "steal",
  "block",
  "turnover",
] as const;

type StatColumn = (typeof statColumns)[number];
const statOrder = (stat: StatColumn): number => statColumns.findIndex(v => v === stat);
type StatRow = Record<StatColumn, string | number>;

const mapStats = (stats: StatAggregate) => {
  const statRow: StatRow = {
    // games_played: 1, // TODO
    shots_made_shots_attempted: `${stats.basketball_score}/${calculateShotsAttempted(stats)}`,
    field_goal_percentage: percentageString(calculateFGP(stats)),
    rebound: stats.basketball_rebound,
    assist: stats.basketball_assist,
    steal: stats.basketball_steal,
    block: stats.basketball_block,
    turnover: stats.basketball_turnover,
  };

  const statsRowData: SDData[] = Object.keys(statRow)
    .map(key => {
      return {
        name: key as StatColumn,
        value: statRow[key as StatColumn],
      };
    })
    .sort((a, b) => statOrder(a.name) - statOrder(b.name));

  return statsRowData;
};

const calculateFGP = ({ basketball_score, basketball_miss }: StatAggregate) => {
  if (basketball_score === 0) return 0;
  const combined = basketball_miss + basketball_score;
  if (combined === 0) return 0;
  return basketball_score / combined;
};

const calculateShotsAttempted = ({ basketball_score, basketball_miss }: StatAggregate): number =>
  basketball_score + basketball_miss;

export const configureTableProps = (
  team: PlayerWithDetails[] = [],
  gameStats: GameStatsByPlayer,
  sport: Sport = "basketball",
): [SDTableHeaderData, SDTableData] => {
  let columns: SDTableColumn[];
  let rows: SDPerformer[];

  switch (sport) {
    case "basketball":
      columns = basketballGameStatColumns;
      rows = team.map(player => {
        return {
          id: player.id,
          user: player.user,
          stats: mapStats(gameStats?.[player.id]),
        };
      });
  }

  const headers: SDTableHeaderData = {
    groupId: "stats",
    columns,
  };

  const data: SDTableData = {
    rows,
  };

  return [headers, data];
};
