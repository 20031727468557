import * as Tabs from "@radix-ui/react-tabs";
import { classNames as cn } from "../../lib/utils/classNames";
import { ReactNode } from "react";

export interface TabsConfig {
  ariaLabel: string;
  defaultValue: string;
  tabs: TabData[];
}

export interface TabData {
  value: string;
  content: ReactNode | string | null;
}

const defaultTabsConfig: TabsConfig = {
  ariaLabel: "tabs list",
  tabs: [],
  defaultValue: "",
};

export function FolderTabs({
  tabsConfig = defaultTabsConfig,
  className,
}: {
  tabsConfig: TabsConfig;
  className?: string;
}) {
  return (
    <Tabs.Root defaultValue={tabsConfig.defaultValue} className={className}>
      <Tabs.List
        className="w-full flex px-1 gap-1 shadow-bottomline md:shadow-none md:mx-auto md:max-w-screen-lg"
        aria-label={`tab list`}
      >
        {tabsConfig.tabs.map(tab => (
          <TabsTrigger key={tab.value} value={tab.value}>
            {tab.value}
          </TabsTrigger>
        ))}
      </Tabs.List>
      {tabsConfig.tabs.map(tab => (
        <Tabs.TabsContent key={tab.value} value={tab.value}>
          {tab.content}
        </Tabs.TabsContent>
      ))}
    </Tabs.Root>
  );
}

const TabsTrigger = ({
  children,
  className = "",
  ...props
}: {
  children: string;
  className?: string;
  value: string;
}) => {
  return (
    <Tabs.Trigger
      className={cn(
        "box-border bg-gray-300 border-b-2 border-transparent border-b-black rounded-t text-2xl p-2.5 flex-1 uppercase font-Altivo font-black data-[state=active]:bg-white data-[state=active]:pt-1.5 data-[state=active]:px-2 data-[state=active]:border-solid data-[state=active]:border-2 data-[state=active]:border-t-black data-[state=active]:border-x-black data-[state=active]:border-b-0 md:border-box md:flex-none md:p-2.5 md:border-2 md:border-transparent md:rounded md:data-[state=active]:border-black md:data-[state=active]:border-2 md:data-[state=active]:p-2.5",
        className,
      )}
      {...props}
    >
      {children}
    </Tabs.Trigger>
  );
};
