import { useAuth } from "@clerk/clerk-react";
import { useToast } from "../../components/ui/use-toast";

type Method = "GET" | "POST" | "PATCH" | "PUT" | "DELETE";

export type Request = {
  path: string;
  method: Method;
  body?: any;
  params?: { [key: string]: string | number };
};

export class APIError extends Error {
  statusCode: number;
  message: string;
  constructor(url: URL, statusCode: number, statusText: string, message: string) {
    super(`APIError - URL: ${url.toString()} (${statusCode}) ${statusText} - ${message}`);
    this.statusCode = statusCode;
    this.message = message;
  }
}

export async function runFn<T>(request: Request, token?: string | null): Promise<T> {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = new URL(`${BASE_URL}${request.path}`);
  if (request.params) {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(request.params)) {
      params.append(key, value.toString());
    }
    url.search = params.toString();
  }
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  if (token) {
    headers.append("somedubs-user-token", token);
  }

  console.log(`${request.method} ${url.toString()}`);
  const response = await fetch(url.toString(), {
    method: request.method,
    headers: headers,
    body: JSON.stringify(request.body),
  });

  const text = await response.text();
  if (!response.ok) {
    throw new APIError(url, response.status, response.statusText, text);
  }
  return JSON.parse(text);
}

export function useApi() {
  const { getToken } = useAuth();
  const { toast } = useToast();

  async function run<T>(request: Request): Promise<T> {
    const token = await getToken();
    try {
      return await runFn<T>(request, token);
    } catch (e) {
      if (e instanceof APIError) {
        toast({
          variant: "destructive",
          title: `Error (${e.statusCode})`,
          description: `${e.message}`,
        });
      } else if (e instanceof Error) {
        toast({
          variant: "destructive",
          title: `error`,
          description: `${e.message}`,
        });
      } else {
        toast({
          variant: "destructive",
          title: `error`,
          description: `an unknown error occurred`,
        });
      }
      throw e;
    }
  }
  return {
    run,
  };
}
