import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../api";
import { PlayerWithDetails, TeamsOfPlayers } from "./models";
import { queryKeys } from "./queryKeys";
import { MutationConfig, MutationResult } from "../utils/types";

export function usePlayersBySession(sessionId: string) {
  const { run } = useApi();
  async function getPlayersBySession() {
    return await run<PlayerWithDetails[]>({
      method: "GET",
      path: `/collections/${sessionId}/players`,
    });
  }
  const query = {
    queryKey: queryKeys.session.players(sessionId),
    queryFn: getPlayersBySession,
    cacheTime: 10000,
  };
  return useQuery(query);
}

export function usePlayersByGame(gameId: string) {
  const { run } = useApi();
  async function getPlayersByGame(): Promise<TeamsOfPlayers> {
    return await run<TeamsOfPlayers>({
      method: "GET",
      path: `/games/${gameId}/players`,
    });
  }
  const query = {
    queryKey: queryKeys.games.players(gameId),
    queryFn: getPlayersByGame,
    staleTime: 60000,
  };
  return useQuery(query);
}

type SearchPlayersQuery = UseQueryOptions<PlayerWithDetails[]>;
export function useSearchPlayers(
  { search, limit = 25 }: { search: string; limit?: number },
  config?: Omit<SearchPlayersQuery, "queryFn" | "queryKey">,
) {
  const { run } = useApi();
  async function getPlayersBySearch(): Promise<PlayerWithDetails[]> {
    return await run<PlayerWithDetails[]>({
      method: "GET",
      path: `/players`,
      params: {
        search,
        limit,
      },
    });
  }
  const query: SearchPlayersQuery = {
    ...config,
    queryKey: queryKeys.players.search(search, limit),
    queryFn: getPlayersBySearch,
  };
  return useQuery(query);
}

export function useCreatePlayer(
  config?: MutationConfig<PlayerWithDetails, typeof createPlayer>,
): MutationResult<PlayerWithDetails, typeof createPlayer> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function createPlayer(payload: {
    firstName: string;
    lastName: string;
    collectionId?: string;
    email?: string;
    nickname?: string;
  }) {
    return await run<PlayerWithDetails>({
      method: "POST",
      path: `/players`,
      body: payload,
    });
  }
  return useMutation({
    ...config,
    mutationFn: createPlayer,
    onSettled: async (_, __, { collectionId }) => {
      if (collectionId) {
        // if the player was created within a session
        await queryClient.invalidateQueries({
          queryKey: queryKeys.session.players(collectionId),
        });
      }
    },
  });
}
