import { ReactNode } from "react";

export function TitledSection({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div>
      <h3 className="uppercase font-Altivo font-black text-3xl mb-2">{title}</h3>
      {children}
    </div>
  );
}
