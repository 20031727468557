import { LoadingSpinner } from "../../components/Spinner";
import { AvatarStack } from "../../components/avatar/AvatarStack";
import { Button } from "../../components/Button";
import { DialogTrigger } from "../../components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { useSession } from "../../lib/data/session";
import { usePlayersBySession } from "../../lib/data/players";
import { BasketballDunkYellow } from "../../components/svgr";

export function SessionPageHeader({
  sessionId,
  generateGameForSession,
}: {
  sessionId: string;
  generateGameForSession: () => void;
}) {
  // const { queryPlayersFromSession } = useApi();}
  const { data: session } = useSession(sessionId);
  const { isPending: isPlayersPending, data: playersFromCollection = [] } =
    usePlayersBySession(sessionId);

  return (
    <div className="min-w-sm max-w-xl">
      <div className="flex flex-row">
        <div className="flex flex-col">
          <BasketballDunkYellow
            aria-label="basketball dunk icon"
            width={75}
            // className="w-[120px] h-[120px]"
          />
        </div>
        {session && (
          <div className="flex flex-col gap-2">
            <h3 className="font-Altivo font-black text-lg md:text-2xl">
              {new Date(session.startDate).toLocaleDateString()}
            </h3>
            <div>{session.title}</div>
            {isPlayersPending ? (
              <LoadingSpinner />
            ) : (
              <AvatarStack users={playersFromCollection.map(p => p.user)} />
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col my-4 gap-3">
        <DialogTrigger asChild>
          <Button className="w-full" intent="yellow" label="edit players" />
        </DialogTrigger>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button className="w-full" intent="green" label="add a game +" />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-white-custom">
            <DropdownMenuLabel>Options</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <div onClick={generateGameForSession}>generate game</div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
