import { ReactNode } from "react";
import { classNames } from "../lib/utils/classNames";

export function ContentContainer({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={classNames("px-2.5 mx-auto py-8 max-w-screen-lg", className)}>{children}</div>
  );
}
