import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { PageContainer } from "../../components/PageContainer";
import { BasketballDunkYellow, Laurels, RunningStickYellow } from "../../components/svgr";
import { SportCard } from "../../components/SportCard";
import { Blob2 } from "../../components/svgr/blobs";

export function LandingPage() {
  return (
    <PageContainer>
      <Hero />
      <LargeBanner />
      <SupportedSports />
    </PageContainer>
  );
}

function Hero() {
  return (
    <div className="px-2.5 py-10 max-w-screen-sm m-auto items-center flex flex-col justify-start">
      <h2 className="self-start uppercase text-4.5xl sm:text-5xl font-Altivo mb-2.5 font-black">
        <span>Score Every Moment,</span>
        <br />
        <span className="text-green">Seamlessly</span>
      </h2>
      <p className="mb-9">
        Capture and immortalize each play, turning routine actions into historic moments. Add
        significance to your athletic journey.
      </p>
      <div className="flex gap-6">
        <RunningStickYellow aria-label="running figure" width={100} />
        <Link to="/dashboard">
          <Button className="mt-6" intent={"yellow"} label="let's start" />
        </Link>
      </div>
    </div>
  );
}

function LargeBanner() {
  return (
    <div className="flex w-full min-h-[350px] h-1/2 max-h-[550px] bg-wilt bg-center bg-no-repeat bg-cover backdrop-brightness-50">
      <div className="relative m-auto text-4xl w-fit text-center uppercase font-Altivo font-black flex flex-col drop-shadow-3xl">
        <Laurels
          width={256}
          className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-green"
        />
        <span className="z-10 text-white-custom">Game,</span>
        <span className="z-10 text-yellow">Stats,</span>
        <span className="z-10 text-white-custom">Victory.</span>
      </div>
    </div>
  );
}

function SupportedSports() {
  return (
    <div className="px-2.5 py-10 max-w-screen-sm m-auto items-center flex flex-col justify-start">
      <h2 className="self-start uppercase mb-2.5 text-4xl font-Altivo font-black">
        <span>
          Supported
          <br />
          Sports
        </span>
      </h2>
      <p className="mb-9">
        We’re dedicated to expanding our support to include even more sports. Stay tuned as we
        evolve to embrace the full spectrum of sporting excellence.
      </p>
      <div className="w-full flex gap-6">
        <SportCard label="basketball">
          <BasketballDunkYellow aria-label="basketball dunk icon" width={100} height={100} />
        </SportCard>
        <SportCard className="relative" label="more soon...">
          <Blob2 width={100} height={100} className="fill-green"></Blob2>
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-2xl font-Altivo font-black">
            WIP
          </span>
        </SportCard>
      </div>
    </div>
  );
}
