import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../../components/ui/select";
import { Button } from "../../components/Button";
import { format, formatISO } from "date-fns";
import { useState } from "react";
import { useCreateSession } from "../../lib/data/session";

export function CreateSessionForm({ closeModal }: { closeModal?: () => void }) {
  const [isLoading, setLoading] = useState<boolean>(false);

  const formSchema = z.object({
    sport: z.enum(["basketball"]),
    title: z.string().min(3, "Please enter a session name"),
    location: z.string().optional(),
    startDate: z.string().min(1, "Please enter a date/time"),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      sport: "basketball",
      title: "",
      startDate: "",
    },
  });

  const mutation = useCreateSession({
    onSuccess: closeModal,
    onError: console.log,
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      const values = formSchema.parse(form.getValues());
      const formattedStartDate = formatISO(new Date(values.startDate));
      await mutation
        .mutateAsync({ ...values, startDate: formattedStartDate })
        .finally(() => setLoading(false));
    } catch (e) {
      console.error(`form error: ${JSON.stringify(e)}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="sport"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Sport..." />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="bg-white-custom">
                    <SelectItem value="basketball">Basketball</SelectItem>
                    <SelectItem value="basketball2" disabled={true}>
                      Note: other sports in progress...
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Session Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="startDate"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  type="datetime-local"
                  placeholder="Time"
                  {...field}
                  onChange={e => {
                    try {
                      const formatted = format(
                        new Date(e.target.value),
                        "yyyy-MM-dd'T'HH:mm:ss.SSS",
                      );
                      field.onChange(formatted);
                    } catch (e) {
                      field.onChange(() => "");
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button disabled={isLoading} onClick={onSubmit} label="Create Session" />
      </form>
    </Form>
  );
}
