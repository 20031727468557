import { ReactNode } from "react";

export const SDTableContainer = ({ children }: { children: ReactNode }) => {
  return <div className="pt-4 pb-9">{children}</div>;
};

export const SDTableContainerHeader = ({ title, detail }: { title: string; detail: string }) => {
  return (
    <div className="flex justify-between pb-3 text-xl">
      <span className="uppercase font-Altivo font-black">{title}</span>
      <span className="sans text-base">{detail}</span>
    </div>
  );
};
